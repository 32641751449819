<template>
<div>
    <div class="row page-title align-items-center">
        <div class="col-12">
            <h4 class="mb-1 mt-0">Emergency Procedures</h4>
            <span class="sub-header">
                Customize procedure for your on site members.
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body" v-if="selectedLocation">
                    <div class="border bg-light p-4">
                        <b-row class="mb-0">
                            <b-col sm="12" lg="3">
                                <label class="mt-2 float-right" for="inputEmail3">Category:</label>
                            </b-col>
                            <b-col sm="12" lg="4">
                                <b-form-select v-model="selectedCategory" name="XXXXCategory" class="mb-2" @change="changeWardenType">
                                    <option key="00000000-0000-0000-0000-000000000000" value="" text="Please Select">Select Category</option>
                                    <option v-for="(item,index) in emergencyCategory" :key="index" :value="item" :text="item.name">{{ item.name }}</option>
                                </b-form-select>
                            </b-col>
                        </b-row>
                        <b-row class="mb-0">
                            <b-col sm="3">
                                <label class="mt-2 float-right" for="inputEmail3">Designation:</label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-select v-model="selectedDesignation" name="XXXXDesignation" class="mb-2" @change="changeWardenType">
                                    <option key="00000000-0000-0000-0000-000000000000" value="" text="Please Select">Select Designations</option>
                                    <option v-for="(item,index) in designations" :key="index" :value="item" :text="item.name">{{ item.name }}</option>
                                </b-form-select>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="card-body pt-0" v-if="selectedLocation && procedure">
                    <div class="row">
                        <div class="col-md-3 col-xl-6">
                            <h6 class="header-title">Emergency Procedure: {{ selectedCategory.name }} ({{ selectedDesignation.name }})</h6>
                        </div>
                        <div class="col-md-9 col-xl-6 text-md-right">
                            <button type="button" v-if="!previewMode" @click="save" class="btn btn-primary btn-md mr-2">
                                Save
                            </button>
                            <button type="button" v-if="!previewMode" @click="previewMode = !previewMode" class="btn btn-dark btn-md">
                                Preview
                            </button>
                            <button type="button" v-if="previewMode" @click="previewMode = !previewMode" class="btn btn-dark btn-md">
                                Edit
                            </button>
                        </div>
                    </div>
                    <vs-divider></vs-divider>
                    <div class="border p-2 mt-2" v-show="!previewMode">
                        <ejs-richtexteditor ref="rteObj" :imageUploadSuccess="onImageUploadSuccess" :insertImageSettings="insertImageSettings" v-model="procedure.procedureText" :height="500"></ejs-richtexteditor>
                    </div>
                    <div class="border p-2 mt-2" v-show="previewMode">
                        <div v-html="procedure.procedureText" v-show="previewMode"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    CoreAPIConfig
} from "@state/api/config";

import {
    RichTextEditorPlugin,
    Toolbar,
    Link,
    Image,
    Count,
    HtmlEditor,
    QuickToolbar
} from "@syncfusion/ej2-vue-richtexteditor";

import {
    treeData
} from '@state/api/tree'

import {
    designationData
} from '@state/api/designations';

import {
    emergencyProcedureData
} from '@state/api/procedure';

export default {
    data() {
        return {
            editorContent: "<h1>data</h1>",
            selectedLocation:{
                keyId: '00000000-0000-0000-0000-000000000000'
            },
            selectedDesignation: "",
            selectedCategory: "",
            wardenTypeList: "",
            componentKey: 1,
            showUpload: false,
            emergencyCategory: [],
            designations: [],
            previewMode: false,
            procedure: null,
            insertImageSettings: {
                display: 'inline',
                saveUrl: `${CoreAPIConfig.COREAPI}api/Home/FileUpload`,
                path: "https://storagetawcs.blob.core.windows.net/logocontainer/"
            }
        }
    },
    provide: {
        richtexteditor: [Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar]
    },
    components: {
        RichTextEditorPlugin
    },
    async created() {
        await this.loadDesignations();
        await this.loadCategories();
        await this.changeWardenType();
    },
    methods: {
        onImageUploadSuccess: function (args) {
            var responseData = JSON.parse(args.e.currentTarget.responseText);
            console.log("🚀 ~ file: page.vue ~ line 138 ~ args", responseData)
            if (responseData) {
                args.file.name = responseData.name;
                let filename = document.querySelectorAll(".e-file-name")[0];
                filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0].innerHTML, '');
                filename.title = args.file.name;
            }
        },
        async loadDesignations() {
            this.selectedDesignation = null;
            this.designations = await designationData.searchByLocation();
            if (this.designations.length > 0) {
                this.selectedDesignation = this.designations[0];
            }
        },
        async loadCategories() {
            this.selectedCategory = null;
            this.emergencyCategory = await treeData.searchEmergencyCategory();
            if (this.emergencyCategory.length > 0) {
                this.selectedCategory = this.emergencyCategory[0];
            }
        },
        async changeWardenType() {
            if (this.selectedDesignation && this.selectedCategory && this.selectedLocation) {
                this.procedure = await emergencyProcedureData.search(                    
                    this.selectedCategory.id,
                    this.selectedDesignation.id
                );
                if (!this.procedure) {
                    this.procedure = {
                        locationId: this.selectedLocation.keyId,
                        designationId: this.selectedDesignation.id,
                        categoryId: this.selectedCategory.id,
                        procedureText: ""
                    };
                }
            } else {
                this.procedure = null;
            }
        },
        async save() {

            this.procedure.locationId = this.selectedLocation.keyId;
            this.procedure.designationId = this.selectedDesignation.id;
            this.procedure.categoryId = this.selectedCategory.id;

            let apiResult = await emergencyProcedureData.create(this.procedure);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
        }
    }
}
</script>
