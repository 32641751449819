export const customMessages = {
    required: 'Required',
    alpha_dash: 'only alphabets, numbers and spaces are allowed',
    regex: 'Invalid value, only alphabets, numbers, hypen, spaces are allowed',
    email: 'Invalid Email',
    max: "Reached the limit of characters."
};

export const parseError = error => {
    return error.response.data;
};

export const parseList = response => {
    if (response.status !== 200) throw Error(response.message);   

    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== 'object') {
      list = [];
    }
    return list;
  };