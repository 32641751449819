import { axiosInstance } from '../../axios'
import { parseError,parseList } from "../../utils/validationmessage"

const GETSearchbyLocation = "api/Designation/Search";
const POSTCreate = "api/Designation/Create";
const POSTApplyDefault = "api/Designation/ApplyDefault";
const POSTDelete = "api/Designation/Remove";

const searchByLocation = async function () {
  try {
      const response = await axiosInstance.coreAPIInstance.get(`${GETSearchbyLocation}`);
      return parseList(response);
  } catch (error) {
      return error;
  }      
}

const create = async function (designation) {
  try {
      const response = await axiosInstance.coreAPIInstance.post(POSTCreate, designation);
      return response.data;
  } catch (error) {
      return error;
  }      
}

const applyDefault = async function (designation) {
  try {
      const response = await axiosInstance.coreAPIInstance.post(POSTApplyDefault, designation);
      return response.data;
  } catch (error) {
      return error;
  }      
}

const remove = async function (designation) {
  try {
      const response = await axiosInstance.coreAPIInstance.post(POSTDelete, designation);
      return response.data;
  } catch (error) {
      return error;
  }      
}


export const designationData = {
  searchByLocation,
  create,
  applyDefault,
  remove
}