import { axiosInstance } from '../../axios'

const GETSearchCountry = "api/Tree/SearchCountry";
const GETSearchState = "api/Tree/SearchState/";
const GETSearchEmergencyCategory = "api/EmergencyCategory/Search";

const searchCountry = async function () {
    try {
        const response = await axiosInstance.coreAPIInstance.get(GETSearchCountry);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
}

const searchState = async function (country) {
  try {
      const response = await axiosInstance.coreAPIInstance.get(GETSearchState + country);
      let data = parseList(response);
      return data;
  } catch (error) {
      return [];
  }
}

const searchEmergencyCategory = async function () {
  try {
      const response = await axiosInstance.coreAPIInstance.get(`${GETSearchEmergencyCategory}`);
      let data = parseList(response);
      return data;
  } catch (error) {
      return [];
  }
}

const parseList = response => {
    if (response.status === 204) throw Error("Please Select Company");
    if (response.status !== 200) throw Error(response.message);   

    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== 'object') {
      list = [];
    }
    return list;
  };
  

export const treeData = {
  searchCountry,
  searchState,
  searchEmergencyCategory
}