import { axiosInstance } from '../../axios'
import { parseError,parseList } from "../../utils/validationmessage"

const GETSearch = "api/EmergencyProcedure/Search";
const POSTCreate = "api/EmergencyProcedure/Create";

const search = async function (categoryId, designationId) {
  try {
      const response = await axiosInstance.coreAPIInstance.get(`${GETSearch}/${categoryId}/designation/${designationId}`);
      return response.data;
  } catch (error) {
      return error;
  }      
}

const create = async function (procedure) {
  try {
      const response = await axiosInstance.coreAPIInstance.post(POSTCreate, procedure);
      return response.data;
  } catch (error) {
      return error;
  }      
}

export const emergencyProcedureData = {
  search,
  create  
}